import queryString from 'query-string';
import useSWR from 'swr';
import { ShopProductFilter } from '../interface';
import { getShopProductStats } from '../queries';

const useShopProductStats = (params: ShopProductFilter) => {
  const query = queryString.stringify(params, { arrayFormat: 'comma' });

  const { data, isValidating } = useSWR(
    `/shop-products/stats${query}`,
    () => getShopProductStats(params),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return {
    shopProductStats: data,
    isLoading: isValidating
  };
};

export default useShopProductStats;
