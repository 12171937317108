import Axios from 'axios';
import queryString from 'query-string';
import { UserSummary } from 'services/groups';
import {
  GetShopProductPublisherParams,
  PriceRange,
  ShopProductFilter,
  ShopProductSearchFilter,
  ShopProductSummary
} from './interface';

interface SearchShopProductsResponse {
  item_summary: {
    type: 'SHOP_PRODUCT';
    shop_product_summary?: ShopProductSummary;
  };
}

export const searchShopProducts = async (filter: ShopProductSearchFilter) => {
  try {
    const query = queryString.stringify(filter, { arrayFormat: 'comma' });

    const response = await Axios.get<SearchShopProductsResponse[]>(
      `${process.env.REACT_APP_SEARCH_API_HOST}/api/v1/shop-products/search?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

interface GetShopProductsResponse {
  item_summary: {
    shop_product_summary: ShopProductSummary;
  };
}

export const getShopProducts = async (filter: ShopProductFilter) => {
  try {
    const query = queryString.stringify(filter, { arrayFormat: 'comma' });

    const response = await Axios.get<GetShopProductsResponse[]>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/filter?${query}`
    );

    if (response.status === 200)
      return {
        data: response.data.map(item => item.item_summary.shop_product_summary),
        totalCount: response.headers['x-total-count'] as number
      };

    return null;
  } catch (error) {
    return null;
  }
};

export const getShopProductsPriceHistogram = async (
  filter: ShopProductFilter
) => {
  try {
    const query = queryString.stringify(filter, { arrayFormat: 'comma' });

    const response = await Axios.get<PriceRange[]>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/price-histogram?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

export interface ShopProductStatsItem {
  tag: string;
  count: number;
}

export interface ShopProductStatsResponse {
  categories: ShopProductStatsItem[];
  amenities: ShopProductStatsItem[];
  languages: ShopProductStatsItem[];
  diets: ShopProductStatsItem[];
  meals: ShopProductStatsItem[];
  atmospheres: ShopProductStatsItem[];
}

export const getShopProductStats = async (params: ShopProductFilter) => {
  try {
    const query = queryString.stringify(params, { arrayFormat: 'comma' });

    const response = await Axios.get<ShopProductStatsResponse>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/stats?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

interface GetAccommodationStatsResponse {
  product_id: string;
  option_id: string;
  number_of_tickets_for_sale: number;
  number_of_sales: number;
}

export const getAccommodationStats = async (productId: string) => {
  try {
    const response = await Axios.get<GetAccommodationStatsResponse[]>(
      `${process.env.REACT_APP_MARKETPLACE_API_HOST}/api/v1/shop_products/${productId}/retreat/accommodation-options/stats`,
      {
        timeout: 10000
      }
    );

    if (response.status === 200) return response.data;

    return null;
  } catch {
    return null;
  }
};

interface GetShopProductDetailsBySlugParams {
  slug: string;
  params: {
    include_pending?: boolean;
  };
}

export const getShopProductDetailsBySlug = async ({
  slug,
  params
}: GetShopProductDetailsBySlugParams) => {
  try {
    const query = queryString.stringify(params, { arrayFormat: 'comma' });

    const response = await Axios.get<ShopProductSummary>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/${slug}/slug?${query}`
    );

    if (response.status === 200) return response.data;

    return null;
  } catch (error) {
    return null;
  }
};

interface GetShopProductPublisherResponse {
  item_summary: {
    user_summary: UserSummary;
  };
}
export const getShopProductPublishers = async (
  params: GetShopProductPublisherParams
) => {
  try {
    const query = queryString.stringify(params, { arrayFormat: 'comma' });

    const response = await Axios.get<GetShopProductPublisherResponse[]>(
      `${process.env.REACT_APP_FILTER_API_HOST}/api/v1/shop-products/publishers?${query}`
    );

    return response.data.map(item => item.item_summary.user_summary);
  } catch {
    return null;
  }
};
