import dayjs from 'dayjs';
import queryString from 'query-string';
import useSWR from 'swr';
import { ShopProductFilter } from '../interface';
import { getShopProducts } from '../queries';

const useGetShopProducts = (filter: ShopProductFilter) => {
  const filterQueryString = queryString.stringify(filter, {
    arrayFormat: 'comma'
  });

  const { data, isValidating } = useSWR(
    `/api/v1/shop-products/filter?${filterQueryString}`,
    async () => {
      const data = await getShopProducts(filter);

      const retreats = data?.data.filter(retreat => {
        return dayjs(retreat.start_date.epoch).isAfter(
          dayjs().subtract(2, 'days'),
          'days'
        );
      });

      return {
        data: retreats,
        totalCount: data?.totalCount
      };
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return {
    shopProducts: data?.data,
    totalCount: data?.totalCount,
    isLoading: isValidating
  };
};

export default useGetShopProducts;
