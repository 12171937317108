import { useAnalytics } from 'hooks/useAnalytics';
import usePrevious from 'hooks/usePrevious';
import GA_EVENTS from 'lib/firebase/ga_events';
import noop from 'lodash/noop';
import without from 'lodash/without';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useShopProductStats from 'services/shops/hooks/useShopProductStats';
import { ShopProductFilter } from 'services/shops/interface';
import { ShopProductStatsResponse } from 'services/shops/queries';

type CheckboxFilterType =
  | 'categories'
  | 'location_names'
  | 'content_langs'
  | 'atmospheres'
  | 'amenities'
  | 'diets'
  | 'owner_ids'
  | 'tags';

interface Context {
  isLoading: boolean;
  isLoadingStats?: boolean;
  totalCount?: number;
  filter?: ShopProductFilter;
  shopProductStats?: ShopProductStatsResponse | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<ShopProductFilter>>;
  onChangeCheckboxFilter: (
    filterType: CheckboxFilterType,
    isChecked: boolean,
    value: string
  ) => void;
  setTotalCount: React.Dispatch<React.SetStateAction<number>>;
}

const RetreatsDiscoveryContext = createContext<Context>({
  isLoading: false,
  isLoadingStats: false,
  totalCount: 0,
  filter: {},
  setIsLoading: noop,
  setFilter: noop,
  onChangeCheckboxFilter: noop,
  setTotalCount: noop
});

export const RetreatsDiscoveryContextProvider: React.FC = ({ children }) => {
  const { trackGAEvent, previousAndCurrentRoute } = useAnalytics();
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<ShopProductFilter>({
    sort_option: 'recommended'
  });
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    shopProductStats: _shopProductStats,
    isLoading: isLoadingStats
  } = useShopProductStats({
    product_types: ['RETREAT'],
    sort_direction: 'desc',
    ...filter
  });

  const prevShopProductStats = usePrevious(_shopProductStats, true);

  const shopProductStats = _shopProductStats || prevShopProductStats;

  useEffect(() => {
    /* 
    TODO: when it's possible to have filter on page load (e.g filter in URL), 
    include the filter to the tracker and keep send it only on first page load
    */

    trackGAEvent({
      event_name: GA_EVENTS.PAGE_VIEW,
      page_name: 'retreats_search_page'
    });
  }, [trackGAEvent]);

  useEffect(() => {
    if (
      previousAndCurrentRoute.previousRoute !== '/retreats' &&
      previousAndCurrentRoute.currentRoute === '/retreats'
    ) {
      setFilter({
        sort_option: 'recommended'
      });
    }
  }, [previousAndCurrentRoute, trackGAEvent]);

  const onChangeCheckboxFilter = (
    filterType: CheckboxFilterType,
    isChecked: boolean,
    value: string
  ) => {
    setFilter(filter => ({
      ...filter,
      [filterType]: isChecked
        ? [...(filter[filterType] || []), value]
        : without(filter[filterType] || [], value)
    }));
  };

  return (
    <RetreatsDiscoveryContext.Provider
      value={{
        isLoading,
        isLoadingStats,
        filter,
        shopProductStats,
        totalCount,
        setIsLoading,
        setFilter,
        onChangeCheckboxFilter,
        setTotalCount
      }}
    >
      {children}
    </RetreatsDiscoveryContext.Provider>
  );
};

export const useRetreatDiscovery = () => {
  return useContext(RetreatsDiscoveryContext);
};
