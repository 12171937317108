import { InsightDateTime } from 'lib/models';
import { UserSummary } from 'services/groups';
import { DateAndTime, Lang } from 'services/interface';
import { MarketplaceModule } from 'services/marketplace';
import { User } from 'services/teacher';

export interface ExternalWorkshopProduct {
  created_at: InsightDateTime;
  description?: string;
  duration_in_mins?: number;
  hashtags?: string[];
  id: string;
  lang?: {
    name: string;
    iso_639_1: string;
  };
  owner: User;
  sort_order?: number;
  status: T_ShopProductStatus;
  title?: string;
  type: 'EXTERNAL_WORKSHOP' | 'EXTERNAL_CONSULTATION';
  updated_at: InsightDateTime;
  url?: string;
}
export interface ShopDetails {
  products: ExternalWorkshopProduct[];
  publisher: UserSummary;
  short_description: string;
  title: string;
}

export type ShopProductContentType = 'WORKSHOP' | 'KEYNOTE' | 'LUNCH_AND_LEARN';

export type ShopProductEventType = 'ZOOM_OR_IN_PERSON' | 'ZOOM' | 'IN_PERSON';

export type SHOP_PRODUCT_TYPES =
  | 'EXTERNAL_CONSULTATION'
  | 'EXTERNAL_WORKSHOP'
  | 'EXTERNAL_MERCHANDISE'
  | 'WORKSHOP'
  | 'WORKSHOP_RECORDING'
  | 'B2B_WORKSHOP_PROMO'
  | 'MARKETPLACE_COURSE'
  | 'RETREAT';

export type T_ShopProductStatus =
  | 'APPROVED'
  | 'PENDING'
  | 'CANCELLED'
  | 'DELETED';

interface StripeRefs {
  price_id?: string;
  product_id?: string;
}

export interface RetreatVenue {
  id: string;
  name: string;
  region: {
    name: string;
    country: string;
    city: string;
    state?: string;
  };
}

export interface ShopProductSummary {
  created_at: DateAndTime;
  short_description?: string;
  description?: string;
  duration_in_mins: number;
  hashtags?: string[];
  lang: Lang;
  owner: UserSummary;
  price: Price;
  prices: Price[];
  sort_order?: number;
  status: T_ShopProductStatus;
  tags?: string[];
  title: string;
  type: SHOP_PRODUCT_TYPES;
  updated_at: DateAndTime;
  url?: string;
  id: string;
  event_id: string;
  start_date: DateAndTime;
  end_date: DateAndTime;
  maximum_number_of_attendees?: number;
  number_of_sales?: number;
  is_religion: boolean;
  is_spirituality: boolean;
  is_newage: boolean;
  is_science: boolean;
  is_secular: boolean;
  is_sold_out?: boolean;
  duration_in_hours: number;
  modules: MarketplaceModule[];
  long_description_markdown: string;
  content_type?: ShopProductContentType;
  waiver?: string;
  recording_summary_header?: string;
  recording_summary_text?: string;
  recording_rating_stars?: number;
  recording_rating_number?: number;
  stripe_refs?: StripeRefs;
  outcomes?: string[];
  inclusions?: string[];
  video_media_paths?: string[];
  event_type?: ShopProductEventType;
  group_id?: string;
  reminder_text?: string;
  duration_text?: string;
  publisher_intro?: string;
  categories?: string[];
  price_text?: string;
  rating_score?: number;
  user_id: string;
  slug?: string;
  editorial_card_slug?: string;
  number_of_tickets_for_sale?: number;
  accommodation?: Accommodation;
  retreat_venue?: RetreatVenue;
  retreat_duration_in_days?: number;
  image_paths?: string[];
  balance_due_days?: number;
  post_cancellation_credit_policy?: string;
}

export interface ShopProductItem {
  item_summary: {
    shop_product_summary: ShopProductSummary;
    type?: 'SHOP_PRODUCT';
  };
}

export type PriceType = 'PER_PERSON' | 'PER_ROOM';

export interface Price {
  currency: 'USD';
  price: number;
  type?: PriceType;
}

export type AccommodationOptionType = 'PRIVATE_ROOM' | 'SHARED_ROOM';

export interface AccommodationOption {
  id: string;
  name: string;
  image_paths?: string[];
  prices: Price[];
  description?: string;
  maximum_occupancy_per_room?: number;
  accommodation_option_type?: AccommodationOptionType;
}

export interface Accommodation {
  description: string;
  options: AccommodationOption[];
}

export enum Atmosphere {
  FOR_SOLO_TRAVELERS = 'solo_travelers',
  LGBTQIA_FRIENDLY = 'lgbtqia',
  WOMEN_ONLY = 'women',
  MEN_ONLY = 'men',
  COUPLES_ONLY = 'couples',
  MOTHER_DAUGHTER = 'mother_daughter',
  FAMILY_FRIENDLY = 'family'
}

export enum Diet {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  VEGAN = 'vegan',
  VEGETARIAN = 'vegetarian',
  HALAL = 'halal',
  KOSHER = 'kosher',
  GLUTEN_FREE = 'gluten_free',
  DAIRY_FREE = 'dairy_free',
  RAW = 'raw',
  ORGANIC = 'organic',
  NUT_ALLERGY_FRIENDLY = 'nut_allergy_friendly',
  DIABETIC_FRIENDLY = 'diabetic_friendly',
  KETO_FRIENDLY = 'keto_friendly',
  PALEO_FRIENDLY = 'paleo_friendly'
}

export enum Amenity {
  AC_IN_ROOMS = 'ac_in_rooms',
  FREE_WIFI = 'free_wifi',
  FITNESS_CENTER = 'fitness_center',
  YOGA_STUDIO = 'yoga_studio',
  POOL = 'pool',
  GARDEN = 'garden',
  SPA = 'spa',
  SAUNA = 'sauna',
  HOT_TUB = 'hot_tub',
  BICYCLES_FOR_RENT = 'bicycles_for_rent',
  LAUNDRY = 'laundry',
  TOWELS = 'towels',
  HOUSEKEEPING = 'housekeeping',
  COFFEE_TEA = 'coffee_tea',
  CAFE = 'cafe',
  RESTAURANT = 'restaurant',
  KITCHEN = 'kitchen',
  WHEELCHAIR_ACCESSIBLE = 'wheelchair_accessible',
  TOUR_ASSISTANCE = 'tour_assistance',
  FREE_PARKING = 'free_parking',
  HEATING = 'heating',
  SHOWER = 'shower',
  PARKING_WITH_FEES = 'parking_with_fees',
  GYM = 'gym',
  TV = 'tv',
  HAIRDRYER = 'hairdryer',
  IRON = 'iron',
  FIRE_PIT = 'fire_pit',
  BBQ_GRILL = 'bbq_grill',
  BATHTUB = 'bathtub'
}

export type ShopProductSortOption =
  | 'Default'
  | 'upcoming'
  | 'popular'
  | 'newest'
  | 'price'
  | 'recommended';

export interface ShopProductFilter {
  query?: string;
  product_types?: ('EXTERNAL_WORKSHOP' | 'EXTERNAL_CONSULTATION' | 'RETREAT')[];
  location_names?: string[];
  country_names?: string[];
  city_names?: string[];
  categories?: string[];
  atmospheres?: Atmosphere[];
  content_langs?: string[];
  amenities?: Amenity[];
  diets?: Diet[];
  tags?: string[];
  status?: 'APPROVED' | 'PENDING' | 'CANCELLED';
  price_start?: number;
  price_end?: number;
  start_date?: number;
  end_date?: number;
  duration?: number;
  max_duration_in_days?: number;
  min_duration_in_days?: number;
  sort_option?: ShopProductSortOption;
  sort_direction?: 'asc' | 'desc';
  limit?: number;
  offset?: number;
  owner_ids?: string[];
}

export interface ShopProductSearchFilter {
  query?: string;
  offset?: number;
  limit?: number;
}

export interface ShopProduct {
  id: string;
  slug?: string;
  title: string;
  owner: UserSummary;
  accommodation?: Accommodation;
  image_paths?: string[];
  retreat_venue: {
    region: {
      country: string;
      name: string;
    };
  };
  start_date: {
    epoch: number;
    iso: string;
  };
  end_date: {
    epoch: number;
    iso: string;
  };
}

export interface PriceRange {
  price: string;
  count: number;
}

export interface GuestDetails {
  index: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface GetShopProductPublisherParams {
  query?: string;
  offset?: number;
  limit?: number;
}
