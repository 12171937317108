import {
  Box,
  Grid,
  GridItem,
  Heading,
  Skeleton,
  SkeletonText,
  VStack
} from '@chakra-ui/react';
import RetreatCardSmall from 'components/RetreatCard/RetreatCardSmall';
import React from 'react';
import useGetShopProducts from 'services/shops/hooks/useGetShopProducts';

const RetreatsLoading = () => {
  return (
    <>
      {[...Array.from(Array(6)).keys()].map(v => (
        <Box key={v} width="100%">
          <Skeleton paddingTop="56.25%" />
          <SkeletonText mt="10px" noOfLines={2} spacing={1} height="46px" />
        </Box>
      ))}
    </>
  );
};

const TopRetreats = () => {
  const { shopProducts: retreats, isLoading } = useGetShopProducts({
    product_types: ['RETREAT'],
    status: 'APPROVED',
    sort_option: 'recommended',
    limit: 6
  });
  return (
    <VStack align="start" width="100%">
      <Heading as="h2" fontSize={18} fontFamily="ProximaNova">
        Top Retreats
      </Heading>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)'
        }}
        gap={6}
        width="100%"
      >
        {isLoading ? (
          <RetreatsLoading />
        ) : (
          retreats?.map(retreat => (
            <GridItem key={retreat.id}>
              <RetreatCardSmall shopProduct={retreat} />
            </GridItem>
          ))
        )}
      </Grid>
    </VStack>
  );
};

export default TopRetreats;
