import { Heading, Link, VStack } from '@chakra-ui/react';
import React from 'react';

const CATEGORIES = [
  {
    title: 'All Retreats',
    url: '/retreats'
  },
  {
    title: 'Yoga Retreats',
    url: '/retreats/yoga'
  },
  {
    title: 'Wellness Retreats',
    url: '/retreats/health-and-wellness'
  },
  {
    title: 'Meditation Retreats',
    url: '/retreats/meditation'
  }
];

interface CategoriesProps {
  onLinkClick?: () => void;
}

const RetreatCategories = ({ onLinkClick }: CategoriesProps) => {
  return (
    <VStack
      align="start"
      flexShrink={0}
      width={{ base: '100%', lg: 100, xl: 180 }}
    >
      <Heading as="h2" fontSize={18} fontFamily="ProximaNova">
        Retreat categories
      </Heading>
      {CATEGORIES.map(category => (
        <Link
          as="a"
          key={category.title}
          href={category.url}
          target="_blank"
          fontFamily="ProximaNova"
          fontSize={16}
          onClick={onLinkClick}
          _hover={{ textDecoration: 'underline' }}
        >
          {category.title}
        </Link>
      ))}
    </VStack>
  );
};

export default RetreatCategories;
